import React, { useContext, useState } from 'react';
import { post } from '../../../utils/apiMethods';
import CurrentTime from '../../CurrentTime';
import Crums from '../../BreadCrums/Crums';
import ApiConstant from '../../../utils/apiConstant';
import routeConstant, { USER_DETAILS_PATH } from '../../../router/PathConst';
import HeaderAPIKeyModal from '../../../modals/dashboard/HeaderAPIKeyModal';

import './Header.css';
import { Divider, Menu, MenuItem } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import UserContext from '../../../context/user/UserContext';
import ConfigContext, {
  initialConfigState,
} from '../../../context/config/ConfigContext';
import Cookies from 'js-cookie';

export default function HeaderNew(props) {
  const history = useHistory();

  const { currentUser, resetCurrentUser } = useContext(UserContext);
  const { setCurrentConfig } = useContext(ConfigContext);

  const [anchorEl, setAnchorEl] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [isAPIKeyModal, setIsAPIKeyModal] = useState(false);
  const [isAPILoading, setIsAPILoading] = useState(false);

  function toggleIsAPIKeyModal() {
    setIsAPIKeyModal(!isAPIKeyModal);
  }

  const toggleProfileMenu = () => {
    setAnchorEl(!anchorEl);
  };

  const toggleProfileMenuForMobile = () => {
    toggleProfileMenu();
    setMobileMenu(!mobileMenu);
  };

  async function logoutHandler() {
    try {
      setIsAPILoading(true);

      const URL = ApiConstant.GET_SIGN_OUT;

      const response = await post({ url: URL, type: 'v2' });

      if (response.status === 200) {
        await resetCurrentUser();

        setCurrentConfig(initialConfigState.currentConfig);

        if (window.location.toString().includes('localhost')) {
          await Cookies.remove('idToken', {
            Domain: 'localhost',
          });
        }

        history.push({
          pathname: `${routeConstant.LOGIN}`,
        });
      } else {
        toast.error('Sign out un-successful!');
      }
    } catch (error) {
      toast.error('Sign out unsuccessful!');
      console.error('Error fetching data:', error);
    } finally {
      setIsAPILoading(false);
    }
  }

  return (
    <>
      <div className="dp-wrapper">
        <div className="dp-section" id="dp-section_content">
          <div className="dp-sction-data-1">
            <div className="dp-section-data-group-1">
              <div
                data-testid="menu-icon"
                onClick={() => props.handleToggle()}
                className="dp-menu-icon"
              />
              <a
                className="link-a"
                href="https://datapelago.io/"
                target="_blank"
                rel="noreferrer"
              >
                <div role="button" className="dp-logo" />
              </a>
              <div className="dp-breadcrumb">
                <Crums items={props.crumbs} history={props.history} />
              </div>
            </div>
          </div>
          <div className="dp-sction-data-2">
            <div className="dp-section-data-group-2">
              <div className="dp-right-icon-group">
                <div className="dp-date-time-marker">
                  <CurrentTime className="dp-date-time-marker" />
                </div>

                <div className="dp-user-name">
                  Hi {currentUser?.given_name}!
                </div>

                <div
                  onClick={toggleProfileMenu}
                  data-testid="user-profile"
                  className="dp-profile-icon"
                />

                <Menu
                  className="profile-menu"
                  id="profile-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={
                    mobileMenu ? toggleProfileMenuForMobile : toggleProfileMenu
                  }
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  style={{ top: '2em' }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  {mobileMenu && (
                    <>
                      <MenuItem onClick={() => {}}>
                        <div className="dp-profile-icon mx-0 text-break" />
                        Hi {currentUser?.given_name}!
                      </MenuItem>
                      <MenuItem
                        className="d-flex flex-column align-items-start"
                        style={{ opacity: '0.80' }}
                        onClick={() => {}}
                        disabled
                      >
                        <p
                          className="mb-1"
                          style={{
                            color: 'var(--clr-text-300)',
                            fontSize: '14px',
                          }}
                        >
                          Date/Time:
                        </p>
                        <CurrentTime
                          className="dp-date-time-marker ml-0"
                          style={{
                            color: 'var(--clr-text-300)',
                            fontSize: '14px',
                          }}
                        />
                      </MenuItem>
                      <Divider />
                    </>
                  )}
                  <MenuItem
                    data-testid="my-profile"
                    onClick={() =>
                      props.history.push({
                        pathname: `${USER_DETAILS_PATH}/${currentUser?.user_id}`,
                        state: {
                          isProfile: true,
                        },
                      })
                    }
                  >
                    My Profile
                  </MenuItem>
                  <MenuItem
                    data-testid="account-settings"
                    onClick={() =>
                      props.history.push({
                        pathname: `${routeConstant.TENANT_DETAILS}`,
                        state: {
                          isProfile: true,
                        },
                      })
                    }
                  >
                    Account Settings
                  </MenuItem>
                  <MenuItem onClick={toggleIsAPIKeyModal}>API key</MenuItem>
                  <MenuItem onClick={isAPILoading ? null : logoutHandler}>
                    {isAPILoading ? 'Signing Out...' : 'Sign Out'}
                  </MenuItem>
                </Menu>
              </div>
              <div
                className="dp-right-menu-icon"
                onClick={toggleProfileMenuForMobile}
              />
            </div>
          </div>
        </div>
      </div>

      <HeaderAPIKeyModal
        show={isAPIKeyModal}
        handleClose={toggleIsAPIKeyModal}
        apiKey={currentUser?.api_key}
      />
    </>
  );
}
