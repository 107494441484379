import React, { Component } from 'react';
import Layout from '../../../components/layout/SideBar/Layout';
import CommonCard from '../../../components/CommonCard/CommonCard';
import {
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import {
  EnhancedTableHead,
  getComparator,
  stableSort,
} from '../../../components/TableComponent/TableComponent';
import routeConstant from '../../../router/PathConst';
import QueryProfileMenu from './QueryProfileMenu';
import { get, put } from '../../../utils/apiMethods';
import ApiConstant from '../../../utils/apiConstant';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import FloatingLabelDropdown from '../../../components/FloatingLabelDropdown/FloatingLabelDropdown';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import QueryProfileDetails from './QueryProfileDetails';
import UserContext from '../../../context/user/UserContext';

const crumbs = [
  {
    title: 'Query History',
    path: routeConstant.QUERY_PROFILE,
    active: false,
  },
];

const headCells = [
  {
    id: 'connecton_id',
    numeric: false,
    disablePadding: false,
    label: 'Connection Id',
  },
  {
    id: 'statement_id',
    numeric: false,
    disablePadding: false,
    label: 'Statement Id',
  },
  {
    id: 'last_modified',
    numeric: false,
    disablePadding: false,
    label: 'Last Modified',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

const initialValues = {
  connectionId: '',
  statementId: '',
  timeType: 'recent',
  interval: '24',
  timeUnit: 'hours',
  startTime: '',
  endTime: '',
};

const validationSchema = Yup.object({});

export default class QueryProfile extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      clusterDetails: {},
      isClusterApiLoading: true,
      queryProfileList: [],
      queryProfileRefList: [],
      queryProfileAPIParams: {
        offset: 0,
        limit: 1000,
      },
      order: 'asc',
      orderBy: 'name',
      rowsPerPage: 10,
      page: 0,
      filter: [],
      uuId: [],
      loading: false,
      isDone: false,
      queryProfileId: null,
    };
    this.onRenderProfileQuery = this.onRenderProfileQuery.bind(this);
  }

  componentDidMount() {
    if (this.context) {
      const { currentUser } = this.context;
      const { props } = currentUser;

      if (props && props.sorting && props.sorting !== undefined) {
        const sort = JSON.parse(props.sorting);

        if (
          sort.clusterQueryProfile !== undefined &&
          sort.clusterQueryProfile.length > 0
        ) {
          this.setState({
            orderBy: sort.clusterQueryProfile[0],
            order: sort.clusterQueryProfile[1],
          });
        } else {
          this.setState({ orderBy: 'name', order: 'asc' });
        }
      } else {
        this.setState({ orderBy: 'name', order: 'asc' });
      }
    }

    this.getClusterDetails();
    this.getQueryProfileList(initialValues);
  }

  getClusterDetails = async () => {
    const clusterId = this.props.match.params.clusterId;

    const URL = `${ApiConstant.GET_CLUSTER}/${clusterId}`;

    const response = await get({ url: URL });

    if (response && response !== undefined) {
      this.setState({ clusterDetails: response, isClusterApiLoading: false });
    } else {
      this.setState({ isClusterApiLoading: false });
    }
  };

  getQueryProfileListHandler = async (values) => {
    this.setState({
      queryProfileList: [],
      queryProfileRefList: [],
      loading: true,
      isDone: false,
      queryProfileAPIParams: {
        ...this.state.queryProfileAPIParams,
        offset: 0,
      },
    });

    this.getQueryProfileList(values);
  };

  getQueryProfileList = async (values) => {
    const clusterId = this.props.match.params.clusterId;

    const response = await get({
      url: ApiConstant.GET_QUERY_PROFILES(
        this.state.queryProfileAPIParams?.offset,
        this.state.queryProfileAPIParams?.limit,
        {
          cluster_id: clusterId,
          connection_id: values?.connectionId,
          statement_id: values?.statementId,
          ...(values?.timeType === 'recent'
            ? {
                interval:
                  values?.timeUnit === 'minutes'
                    ? parseInt(values?.interval) * 60
                    : values?.timeUnit === 'hours'
                      ? parseInt(values?.interval) * 3600
                      : parseInt(values?.interval) * 86400,
              }
            : {
                start_time: moment(values?.startTime)
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss'),
                end_time: moment(values?.endTime)
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss'),
              }),
        }
      ),
    });

    if (response?.length === this.state.queryProfileAPIParams?.limit) {
      this.setState({
        queryProfileAPIParams: {
          ...this.state.queryProfileAPIParams,
          offset:
            this.state.queryProfileAPIParams?.offset +
            this.state.queryProfileAPIParams?.limit,
        },
      });
      this.getQueryProfileList();
    }

    this.setState({
      queryProfileList: [
        ...this.state.queryProfileList,
        ...(response?.map((profile) => ({
          ...profile,
          statement_id: parseInt(profile.statement_id, 10),
        })) ?? []),
      ],
      queryProfileRefList: [
        ...this.state.queryProfileRefList,
        ...(response?.map((profile) => ({
          ...profile,
          statement_id: parseInt(profile.statement_id, 10),
        })) ?? []),
      ],
      loading: false,
      isDone: true,
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === 'asc';

    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property }, () => {
      this.onSaveSort(property, isAsc ? 'desc' : 'asc');
    });
  };

  // save sort
  onSaveSort = (value, order) => {
    const { currentUser } = this.context;

    const { props } = currentUser;

    let array = { ...props };

    if (props && props.sorting) {
      let { sorting } = props;

      const obj = JSON.parse(sorting);
      if (sorting !== undefined) {
        obj.clusterQueryProfile = [value, order];
      } else {
        obj.clusterQueryProfile = [value, order];
      }
      array.sorting = JSON.stringify(obj);

      this.callAPi(array);
    } else {
      const obj = {
        clusterQueryProfile: value,
      };

      currentUser.props = { sorting: JSON.stringify(obj) };
      this.callAPi(currentUser.props);
    }
  };

  callAPi = async (properties) => {
    const { currentUser, storeCurrentUser } = this.context;

    const { username, firstName, lastName, email, password, phone, id } =
      currentUser;

    const payload = {
      id: id,
      username: username,
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      phone: phone,
      props: properties,
    };

    const url = ApiConstant.PUT_USER(id);

    const response = await put({
      url: url,
      payload: payload,
    });

    if (response && response.id) {
      if ('sorting' in response.props) {
        storeCurrentUser(response);
      }
    }
  };

  onFilter = (status) => {
    const { queryProfileRefList } = this.state;

    const filterList = queryProfileRefList?.filter(
      (data) => data.status.toLowerCase() === status.toLowerCase()
    );

    if (filterList && filterList.length > 0) {
      return filterList;
    }
  };

  onFilterData = (value) => {
    let resArray = [];
    const { queryProfileRefList } = this.state;
    if (value && value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        const result = this.onFilter(value[i].value);
        if (result !== undefined) {
          if (result && result.length > 1) {
            for (let k = 0; k < result.length; k++) {
              resArray.push(result[k]);
            }
          } else {
            resArray.push(result[0]);
          }
        }
      }
      this.setState({ queryProfileList: resArray, page: 0 });
    } else {
      this.setState({ queryProfileList: queryProfileRefList });
    }
  };

  onSearchData = (value) => {
    const { queryProfileRefList } = this.state;
    if (value !== '' && value !== undefined) {
      const result = queryProfileRefList?.filter(
        (data) =>
          data.name.toLowerCase().includes(value.toLowerCase()) ||
          data.id.toLowerCase().includes(value.toLowerCase())
      );
      this.setState({ queryProfileList: result, page: 0 });
    } else {
      this.setState({ queryProfileList: queryProfileRefList });
    }
  };

  onDeleteDone = () => {
    this.setState({ queryProfileList: [], loading: true, page: 0 }, () => {
      this.getQueryProfileList();
    });
  };

  onRenderProfileQuery = (id) => {
    this.setState({ queryProfileId: id });
  };

  setQueryProfileId = () => {
    this.setState({ queryProfileId: null });
  };

  render() {
    const {
      clusterDetails,
      isClusterApiLoading,
      order,
      orderBy,
      rowsPerPage,
      page,
      queryProfileList,
      loading,
      isDone,
    } = this.state;

    return (
      <Layout
        crumbs={
          !this.state.queryProfileId
            ? crumbs
            : [
                ...crumbs,
                {
                  title: 'Query History Details',
                  path: routeConstant.QUERY_PROFILE_DETAILS,
                  active: true,
                },
              ]
        }
        {...this.props}
      >
        {isClusterApiLoading ? (
          <SkeletonLoader />
        ) : (
          <>
            <CommonCard
              className={`mb-4 ${this.state.queryProfileId ? 'd-none' : ''}`}
            >
              <div className="details-data-row mb-4">
                <h6 className="details-data-label">Cluster Name:</h6>
                <h6
                  className="details-data-value copy-id"
                  onClick={() =>
                    this.props.history.push(
                      routeConstant.CLUSTER_DETAILS(clusterDetails?.id)
                    )
                  }
                >
                  {clusterDetails.name}
                </h6>
              </div>
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={this.getQueryProfileListHandler}
              >
                {({
                  values,
                  touched,
                  errors,
                  setFieldValue,
                  isSubmitting,
                  handleChange,
                }) => (
                  <Form className="filter-section query-profile">
                    <TextField
                      autoComplete="off"
                      style={{ width: '100%' }}
                      id="outlined-basic"
                      variant="outlined"
                      name="connectionId"
                      label="Connection Id"
                      InputLabelProps={{
                        required: false,
                      }}
                      value={values.connectionId}
                      onChange={handleChange}
                      error={Boolean(errors.connectionId)}
                      helperText={errors.connectionId}
                    />

                    <TextField
                      autoComplete="off"
                      style={{ width: '100%' }}
                      id="outlined-basic"
                      variant="outlined"
                      name="statementId"
                      label="Query Id"
                      InputLabelProps={{
                        required: false,
                      }}
                      value={values.statementId}
                      onChange={handleChange}
                      error={Boolean(errors.statementId)}
                      helperText={errors.statementId}
                    />

                    <FloatingLabelDropdown
                      labelStyle={{ fontSize: '14px' }}
                      name="timeType"
                      label="Time range"
                      value={values?.timeType}
                      required
                      handleChange={(e) => {
                        setFieldValue(e.target.name, e.target.value);
                      }}
                    >
                      <MenuItem value="recent">Recent</MenuItem>
                      <MenuItem value="time-interval">Start/End time</MenuItem>
                    </FloatingLabelDropdown>

                    {values?.timeType === 'recent' ? (
                      <>
                        <TextField
                          autoComplete="off"
                          style={{ width: '100%' }}
                          id="outlined-basic"
                          variant="outlined"
                          name="interval"
                          label="Interval"
                          InputLabelProps={{
                            required: false,
                          }}
                          value={values.interval}
                          onChange={handleChange}
                          error={Boolean(errors.interval)}
                          helperText={errors.interval}
                          required={values?.timeType === 'recent'}
                        />
                        <FloatingLabelDropdown
                          labelStyle={{ fontSize: '14px' }}
                          name="timeUnit"
                          label="Time unit"
                          value={values?.timeUnit}
                          required
                          handleChange={(e) => {
                            setFieldValue(e.target.name, e.target.value);
                          }}
                        >
                          <MenuItem value="minutes">Minutes</MenuItem>
                          <MenuItem value="hours">Hours</MenuItem>
                          <MenuItem value="days">Days</MenuItem>
                        </FloatingLabelDropdown>
                      </>
                    ) : (
                      <>
                        <div className="w-100 position-relative">
                          <p
                            className="mb-0 position-absolute"
                            style={{
                              backgroundColor: 'var(--clr-input-background)',
                              fontSize: '0.7rem',
                              left: '1rem',
                              top: '-1rem',
                              padding: '0.4rem',
                            }}
                          >
                            Start Time
                          </p>
                          <input
                            className="form-control input-boxes w-100"
                            style={{
                              height: '2.8rem',
                            }}
                            type="datetime-local"
                            id="dateTimePicker"
                            name="startTime"
                            value={values?.startTime}
                            onChange={handleChange}
                            required={values?.timeType !== 'recent'}
                          />
                        </div>
                        <div className="w-100 position-relative">
                          <p
                            className="mb-0 position-absolute"
                            style={{
                              backgroundColor: 'var(--clr-input-background)',
                              fontSize: '0.7rem',
                              left: '1rem',
                              top: '-1rem',
                              padding: '0.4rem',
                            }}
                          >
                            End Time
                          </p>
                          <input
                            className="form-control input-boxes w-100"
                            style={{
                              height: '2.8rem',
                            }}
                            type="datetime-local"
                            id="dateTimePicker"
                            name="endTime"
                            value={values?.endTime}
                            onChange={handleChange}
                            required={values?.timeType !== 'recent'}
                          />
                        </div>
                      </>
                    )}
                    <button
                      className="buttonX"
                      type="submit"
                      style={{ width: '100%', gridColumn: 'span 1 / -1' }}
                      disabled={loading}
                    >
                      Get Query List
                    </button>
                  </Form>
                )}
              </Formik>
            </CommonCard>
            <CommonCard
              className={`${this.state.queryProfileId ? 'd-none' : ''}`}
            >
              <div className="desktop-table">
                <Table
                  className="tableX"
                  sx={{
                    minWidth: 750,
                    borderCollapse: 'separate',
                  }}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    rowCount={queryProfileList.length}
                    headCells={headCells}
                  />
                  <>
                    {isDone &&
                    queryProfileList &&
                    queryProfileList.length > 0 ? (
                      <>
                        <TableBody className="table Table">
                          {stableSort(
                            queryProfileList,
                            getComparator(order, orderBy)
                          )
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((queryProfileData, index) => {
                              return (
                                <TableRow
                                  hover
                                  tabIndex={-1}
                                  key={'pc-' + index}
                                >
                                  <TableCell align="left">
                                    {queryProfileData.connecton_id}
                                  </TableCell>

                                  <TableCell
                                    align="left"
                                    onClick={() => {
                                      const base54 = btoa(queryProfileData.key);
                                      this.onRenderProfileQuery(base54);
                                    }}
                                  >
                                    <span className="id">
                                      {queryProfileData.statement_id}{' '}
                                    </span>
                                  </TableCell>

                                  <TableCell align="left">
                                    {moment(
                                      queryProfileData.last_modified,
                                      'YYYY-MM-DD HH:mm:ss Z z'
                                    ).format('MM/DD/YYYY, hh:mm:ss A')}
                                  </TableCell>

                                  <TableCell>
                                    <QueryProfileMenu
                                      clusterId={clusterDetails.id}
                                      queryProfileData={queryProfileData}
                                      onRenderProfileQuery={
                                        this.onRenderProfileQuery
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </>
                    ) : null}
                  </>
                </Table>

                {loading && !isDone ? (
                  <SkeletonLoader />
                ) : !loading &&
                  isDone &&
                  queryProfileList &&
                  queryProfileList.length > 0 ? (
                  <TablePagination
                    className="table-pagination"
                    rowsPerPageOptions={[10, 20, 30, 50, 100]}
                    component="div"
                    count={queryProfileList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                    showLastButton={true}
                    showFirstButton={true}
                  />
                ) : !loading &&
                  queryProfileList &&
                  queryProfileList.length === 0 ? (
                  <p className="text_color text-center no-data-msg mt-2">
                    No Data Found
                  </p>
                ) : null}
                <></>
              </div>
              <div className="mobile-table">
                {loading ? (
                  <SkeletonLoader />
                ) : isDone && queryProfileList && queryProfileList.length ? (
                  queryProfileList.map((queryProfileData, index) => {
                    return (
                      <div key={'mobile-' + index} className="mob-main">
                        <div className="mob-rows">
                          <div className="keys">{headCells[0].label}</div>
                          <div className="values">
                            {queryProfileData.connecton_id}
                          </div>
                        </div>

                        <div className="mob-rows">
                          <div className="keys">{headCells[1].label}</div>
                          <div
                            className="values id"
                            onClick={() => {
                              const base54 = btoa(queryProfileData.key);
                              this.onRenderProfileQuery(base54);
                            }}
                          >
                            {queryProfileData.statement_id}
                          </div>
                        </div>

                        <div className="mob-rows">
                          <div className="keys">{headCells[2].label}</div>
                          <div className="values">
                            {moment(
                              queryProfileData.last_modified,
                              'YYYY-MM-DD HH:mm:ss Z z'
                            ).format('MM/DD/YYYY, hh:mm:ss A')}
                          </div>
                        </div>

                        <div className="mob-rows">
                          <div className="keys">{headCells[3].label}</div>
                          <div>
                            <QueryProfileMenu
                              clusterId={clusterDetails.id}
                              queryProfileData={queryProfileData}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : !loading &&
                  queryProfileList &&
                  queryProfileList.length === 0 ? (
                  <p className="text_color text-center no-data-msg mt-2">
                    No Data Found
                  </p>
                ) : null}
              </div>
            </CommonCard>
          </>
        )}
        {this.state.queryProfileId && (
          <>
            <section className="d-flex justify-content-between">
              <button className="buttonX mb-4" onClick={this.setQueryProfileId}>
                Back
              </button>

              <button
                className="buttonX mb-4"
                onClick={() => {
                  window.open(
                    `/#${routeConstant.QUERY_PROFILE_DETAILS(clusterDetails.id, this.state.queryProfileId)}`
                  );
                }}
              >
                Query Profile (new tab)
              </button>
            </section>

            <QueryProfileDetails
              clusterId={clusterDetails.id}
              queryProfileId={this.state.queryProfileId}
            />
          </>
        )}
      </Layout>
    );
  }
}
