export const DASHBOARD_PATH = '/dashboard';
export const CLUSTER_PATH = '/cluster';
export const CREATE_CLUSTER_PATH = '/cluster/create';
export const CLUSTER_DETAILS_PATH = '/cluster/details';

export const TENANT_PATH = '/tenant';

export const ROLES_PATH = '/roles';
export const CREATE_PERMISSION_PATH = '/permission';

export const CREATE_ROLES_PATH = '/roles';
export const ROLE_DETAILS_PATH = '/role/details';
export const USER_PATH = '/users';
export const CREATE_USER_PATH = '/users';
export const USER_DETAILS_PATH = '/users/details';
export const DATA_SOURCES_PATH = '/datasources';
export const CREATE_DATA_SOURCES_PATH = '/datasources/create';
export const DATA_SOURCES_DETAILS_PATH = '/datasources/details';

export default class routeConstant {
  // Authentication
  static LOGIN = `/login`;
  static FORGOT_PASSWORD = `/forgot-password`;
  static RESET_PASSWORD = `/reset-password`;

  static INDEX = '/';

  /// Tenant Details
  static TENANT_DETAILS = `/tenant/details`;

  /// Dashboard
  static DASHBOARD = '/dashboard';

  /// Cluster
  static CLUSTER_DETAILS = (clusterId) => `/cluster/details/${clusterId}`;

  /// Jobs
  static CLUSTER_JOB_DETAILS = (clusterId, jobId) =>
    `/cluster/${clusterId}/job/details/${jobId}`;

  /// Query Profile
  static QUERY_PROFILE = (clusterId) => `/cluster/${clusterId}/query-profiles`;
  static QUERY_PROFILE_DETAILS = (clusterId, queryProfileId) =>
    `/cluster/${clusterId}/query-profile/details/${queryProfileId}`;
  /// Identity Provider
  static IDENTITY_PROVIDER = '/identity-provider-settings';

  // Cloud Configuration
  static CLOUD_CONFIGURATION = '/cloud-configuration';

  // User Groups
  static USER_GROUPS = '/user-groups';
  static USER_GROUP_ADD_EDIT = (type) => `/user-groups/${type}`;
  static USER_GROUP_DETAILS = (groupId) => `/user-groups/details/${groupId}`;

  // Cloud Profile
  static CLOUD_PROFILES = '/cloud-profiles';
  static CLOUD_PROFILE_ADD_EDIT = (type) => `/cloud-profiles/${type}`;
  static CLOUD_PROFILE_DETAILS = (cloudProfileId) =>
    `/cloud-profiles/details/${cloudProfileId}`;
  static CLOUD_PROFILE_ACTIVATE = (type) => `/cloud-profiles/activate/${type}`;

  /// Audit Logs
  static AUDIT_LOGS = '/audit-logs';
}
