import React, { useState, useEffect, useContext } from 'react';
import Layout from '../../../components/layout/SideBar/Layout';
import CommonCard from '../../../components/CommonCard/CommonCard';
import {
  Collapse,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import {
  EnhancedTableHead,
  getComparator,
  stableSort,
} from '../../../components/TableComponent/TableComponent';
import routeConstant from '../../../router/PathConst';
import { getReadableDate } from '../../../utils/common';
import { get, put } from '../../../utils/apiMethods';
import ApiConstant from '../../../utils/apiConstant';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import FloatingLabelDropdown from '../../../components/FloatingLabelDropdown/FloatingLabelDropdown';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AuditLogsModal from '../../../modals/audit-logs/AuditLogsModal';
import ViewMoreV2 from '../../../modals/Common/ViewMoreV2';
import UserContext from '../../../context/user/UserContext';

const crumbs = [
  {
    title: 'Audit Logs',
    path: routeConstant.QUERY_PROFILE,
    active: false,
  },
];

const headCells = [
  {
    id: 'event_timestamp',
    label: 'Time',
  },
  {
    id: 'event_category',
    label: 'Category',
  },
  {
    id: 'event_type',
    label: 'Type',
  },
  {
    id: 'event_action',
    label: 'Action ',
  },
  {
    id: 'actor',
    label: 'Actor',
  },
  {
    id: 'event_status',
    label: 'Status',
  },
];

const filterDropDownData = {
  actorType: [
    {
      title: 'User',
      value: 'user',
    },
    {
      title: 'Cluster',
      value: 'cluster',
    },
  ],
  eventType: [
    {
      title: 'Role',
      value: 'role',
    },
    {
      title: 'User',
      value: 'user',
    },
    {
      title: 'Cluster',
      value: 'cluster',
    },
    {
      title: 'Auth',
      value: 'auth',
    },
    {
      title: 'Datasource',
      value: 'datasource',
    },
    {
      title: 'Cloud Config',
      value: 'cloud-config',
    },
    {
      title: 'IDP Config',
      value: 'idp-config',
    },
    {
      title: 'AWS Opensearch',
      value: 'aws-opensearch',
    },
    {
      title: 'Permission',
      value: 'permission',
    },
    {
      title: 'Tenant',
      value: 'tenant',
    },
    {
      title: 'Forgot Password',
      value: 'forgot-password',
    },
    {
      title: 'Reset Password',
      value: 'reset-password',
    },
    {
      title: 'AWS S3',
      value: 'aws-s3',
    },
    {
      title: 'Deltalake',
      value: 'deltalake',
    },
    {
      title: 'Tenant Usergroup',
      value: 'tenant-usergroup',
    },
    {
      title: 'AWS Metastore',
      value: 'aws-metastore',
    },
    {
      title: 'AWS Cft',
      value: 'aws-cft',
    },
  ],
  action: [
    {
      title: 'Create',
      value: 'create',
    },

    {
      title: 'Update',
      value: 'update',
    },
    {
      title: 'Delete',
      value: 'delete',
    },
    {
      title: 'Login',
      value: 'login',
    },
    {
      title: 'Logout',
      value: 'logout',
    },
  ],
  status: [
    {
      title: 'Success',
      value: 'success',
    },
    {
      title: 'Failure',
      value: 'failure',
    },
  ],
  orderBy: [
    {
      title: 'Newest First',
      value: 'DESC',
    },
    {
      title: 'Oldest First',
      value: 'ASC',
    },
  ],
};

const initialValues = {
  actor: '',
  actor_type: '',
  event_type: '',
  event_action: '',
  event_status: '',
  sort_order: filterDropDownData?.orderBy[0].value,
  timeType: 'recent',
  interval: '24',
  timeUnit: 'hours',
  startTime: '',
  endTime: '',
};

const validationSchema = Yup.object({});

export default function AuditLogs(props) {
  const { currentUser, storeCurrentUser } = useContext(UserContext);

  const [auditLogs, setAuditLogs] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('event_timestamp');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [isAPILoading, setIsAPILoading] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [responseRequestModalType, setResponseRequestModalType] = useState('');
  const [responseRequestModalData, setResponseRequestModalData] = useState({});
  const [auditLogsTotalCount, setAuditLogsTotalCount] = useState(0);

  const [viewMore, setViewMore] = useState(false);
  const [viewMoreData, setViewMoreData] = useState({});

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (value) => getAuditLogsHandler(value),
  });

  useEffect(() => {
    if (currentUser) {
      const { props } = currentUser;

      if (props && props.sorting) {
        const sort = JSON.parse(props.sorting);
        if (sort.auditLogs?.length > 0) {
          setOrderBy(sort.auditLogs[0]);
          setOrder(sort.auditLogs[1]);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAuditLogsHandler();
  }, [rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    onSaveSort(property, isAsc ? 'desc' : 'asc');
  };

  const onSaveSort = (value, order) => {
    const { props } = currentUser;

    let array = { ...props };

    if (props && props.sorting) {
      let { sorting } = props;

      const obj = JSON.parse(sorting);
      if (sorting !== undefined) {
        obj.auditLogs = [value, order];
      } else {
        obj.auditLogs = [value, order];
      }
      array.sorting = JSON.stringify(obj);

      updateCurrentUser(array);
    } else {
      const obj = {
        auditLogs: value,
      };

      currentUser.props = { sorting: JSON.stringify(obj) };
      updateCurrentUser(currentUser.props);
    }
  };

  const updateCurrentUser = async (properties) => {
    const { id } = currentUser;

    const reqObj = {
      props: properties,
    };

    const URL = `${ApiConstant.PUT_USER(id)}`;

    const response = await put({
      url: URL,
      reqObj: reqObj,
    });

    if (response && response.id) {
      if ('sorting' in response.props) {
        storeCurrentUser(response);
      }
    }
  };

  function responseRequestModalHandler(isVisible, modalTitle, data) {
    setIsModalVisible(isVisible);
    setResponseRequestModalType(modalTitle || '');
    setResponseRequestModalData(data || {});
  }

  function toggleIsDetailsVisible(id) {
    setAuditLogs((prevList) =>
      prevList?.map((audit) =>
        audit.id === id
          ? {
              ...audit,
              isDetailsVisible: !audit.isDetailsVisible,
            }
          : audit
      )
    );
  }

  const getAuditLogsHandler = async () => {
    setAuditLogs([]);

    getAuditLogsAPIHandler();
  };

  const calculateTimes = (timeType, timeUnit, interval, startTime, endTime) => {
    let startTimestamp;
    let endTimestamp;

    if (timeType === 'recent') {
      // Get the current date/time for end time
      const currentDate = new Date();
      endTimestamp = Math.floor(currentDate.getTime() / 1000); // End time is current time in seconds

      let intervalInMillis;

      // Calculate the interval in milliseconds based on the time unit
      switch (timeUnit) {
        case 'minutes':
          intervalInMillis = interval * 60 * 1000;
          break;
        case 'hours':
          intervalInMillis = interval * 60 * 60 * 1000;
          break;
        case 'days':
          intervalInMillis = interval * 24 * 60 * 60 * 1000;
          break;
        default:
          intervalInMillis = 0;
      }

      // Calculate the start time by subtracting the interval from the current time
      const startDate = new Date(currentDate.getTime() - intervalInMillis);
      startTimestamp = Math.floor(startDate.getTime() / 1000); // Start time in seconds
    } else if (timeType === 'time-interval') {
      // Convert the provided startTime and endTime into integer UNIX timestamps
      startTimestamp = Math.floor(new Date(startTime).getTime() / 1000);
      endTimestamp = Math.floor(new Date(endTime).getTime() / 1000);
    }

    return {
      start_time: startTimestamp,
      end_time: endTimestamp,
    };
  };

  const getAuditLogsAPIHandler = async () => {
    try {
      setIsAPILoading(true);

      const {
        actor,
        actor_type,
        event_type,
        event_action,
        event_status,
        sort_order,
        timeType,
        interval,
        timeUnit,
        startTime,
        endTime,
      } = formik?.values;

      const times = calculateTimes(
        timeType,
        timeUnit,
        interval,
        startTime,
        endTime
      );

      const URL = ApiConstant.AUDIT_LOGS(rowsPerPage, page * rowsPerPage, {
        actor,
        actor_type,
        event_type,
        event_action,
        event_status,
        sort_order,
        ...times,
      });

      const response = await get({
        url: URL,
        type: 'v2',
      });

      await setAuditLogs((prevList) => [
        ...(response?.data?.auditlogs?.map((audit) => ({
          ...audit,
          isDetailsVisible: false,
        })) ?? []),
      ]);

      setAuditLogsTotalCount(response?.data?.total_count);
    } catch (error) {
      console.log(error);
    } finally {
      setIsAPILoading(false);
    }
  };

  function openViewMore(data) {
    setViewMore(true);
    setViewMoreData(data);
  }

  function closedViewMore() {
    setViewMore(false);
    setViewMoreData({});
  }

  return (
    <Layout crumbs={crumbs} {...props}>
      <CommonCard className={`mb-4`}>
        <form
          className="d-flex flex-column flex-sm-row justify-content-between gap-4"
          onSubmit={formik.handleSubmit}
        >
          <div className="filter-section" style={{ flex: 1 }}>
            <TextField
              style={{ width: '100%' }}
              autoComplete="off"
              id="outlined-basic"
              variant="outlined"
              name="actor"
              label="Actor"
              InputLabelProps={{
                required: false,
              }}
              value={formik.values.actor}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.actor)}
              helperText={formik.errors.actor}
            />

            <FloatingLabelDropdown
              labelStyle={{ fontSize: '14px' }}
              name="actor_type"
              label="Actor Type"
              value={formik.values?.actor_type}
              handleChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
              }}
            >
              <MenuItem value="">ALL</MenuItem>

              {filterDropDownData?.actorType?.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {item?.title}
                </MenuItem>
              ))}
            </FloatingLabelDropdown>

            <FloatingLabelDropdown
              labelStyle={{ fontSize: '14px' }}
              name="event_type"
              label="Type"
              value={formik.values?.event_type}
              handleChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
              }}
            >
              <MenuItem value="">ALL</MenuItem>
              {filterDropDownData?.eventType?.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {item?.title}
                </MenuItem>
              ))}
            </FloatingLabelDropdown>

            <FloatingLabelDropdown
              labelStyle={{ fontSize: '14px' }}
              name="event_action"
              label="Action"
              value={formik.values?.event_action}
              handleChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
              }}
            >
              <MenuItem value="">ALL</MenuItem>
              {filterDropDownData?.action?.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {item?.title}
                </MenuItem>
              ))}
            </FloatingLabelDropdown>

            <FloatingLabelDropdown
              labelStyle={{ fontSize: '14px' }}
              name="event_status"
              label="Status"
              value={formik.values?.event_status}
              handleChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
              }}
            >
              <MenuItem value="">ALL</MenuItem>
              {filterDropDownData?.status?.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {item?.title}
                </MenuItem>
              ))}
            </FloatingLabelDropdown>

            <FloatingLabelDropdown
              labelStyle={{ fontSize: '14px' }}
              name="sort_order"
              label="Order"
              value={formik.values?.sort_order}
              handleChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
              }}
            >
              {filterDropDownData?.orderBy?.map((item) => (
                <MenuItem value={item?.value} key={item?.value}>
                  {item?.title}
                </MenuItem>
              ))}
            </FloatingLabelDropdown>

            <FloatingLabelDropdown
              labelStyle={{ fontSize: '14px' }}
              name="timeType"
              label="Time range"
              value={formik.values?.timeType}
              handleChange={(e) => {
                formik.setFieldValue(e.target.name, e.target.value);
              }}
            >
              <MenuItem value="recent">Recent</MenuItem>
              <MenuItem value="time-interval">Start/End time</MenuItem>
            </FloatingLabelDropdown>

            {formik.values?.timeType === 'recent' ? (
              <>
                <TextField
                  style={{ width: '100%' }}
                  autoComplete="off"
                  id="outlined-basic"
                  variant="outlined"
                  name="interval"
                  label="Interval"
                  InputLabelProps={{
                    required: false,
                  }}
                  value={formik.values.interval}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.interval)}
                  helperText={formik.errors.interval}
                  required={formik.values?.timeType === 'recent'}
                />
                <FloatingLabelDropdown
                  labelStyle={{ fontSize: '14px' }}
                  name="timeUnit"
                  label="Time unit"
                  value={formik.values?.timeUnit}
                  handleChange={(e) => {
                    formik.setFieldValue(e.target.name, e.target.value);
                  }}
                >
                  <MenuItem value="minutes">Minutes</MenuItem>
                  <MenuItem value="hours">Hours</MenuItem>
                  <MenuItem value="days">Days</MenuItem>
                </FloatingLabelDropdown>
              </>
            ) : (
              <>
                <div className="position-relative">
                  <p
                    className="mb-0 position-absolute"
                    style={{
                      backgroundColor: 'var(--clr-input-background)',
                      fontSize: '0.7rem',
                      left: '1rem',
                      top: '-1rem',
                      padding: '0.4rem',
                    }}
                  >
                    Start Time
                  </p>
                  <input
                    className="form-control input-boxes w-100"
                    style={{
                      height: '2.8rem',
                    }}
                    type="datetime-local"
                    id="dateTimePicker"
                    name="startTime"
                    value={formik.values?.startTime}
                    onChange={formik.handleChange}
                    required={formik.values?.timeType !== 'recent'}
                  />
                </div>
                <div className="position-relative">
                  <p
                    className="mb-0 position-absolute"
                    style={{
                      backgroundColor: 'var(--clr-input-background)',
                      fontSize: '0.7rem',
                      left: '1rem',
                      top: '-1rem',
                      padding: '0.4rem',
                    }}
                  >
                    End Time
                  </p>
                  <input
                    className="form-control input-boxes w-100"
                    style={{
                      height: '2.8rem',
                    }}
                    type="datetime-local"
                    id="dateTimePicker"
                    name="endTime"
                    value={formik.values?.endTime}
                    onChange={formik.handleChange}
                    required={formik.values?.timeType !== 'recent'}
                  />
                </div>
              </>
            )}
          </div>
          <button
            className="buttonX"
            type="submit"
            style={{ height: 'max-content', width: '12rem' }}
            disabled={isAPILoading}
          >
            Get Audit Logs
          </button>
        </form>
      </CommonCard>

      <CommonCard className={`overflow-x-hidden`}>
        <div className="desktop-table1">
          <Table
            className="tableX new"
            sx={{
              borderCollapse: 'separate',
            }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={auditLogs.length}
              headCells={headCells}
            />
            {auditLogs?.length > 0 && (
              <TableBody className="table Table">
                {stableSort(auditLogs, getComparator(order, orderBy)).map(
                  (auditLog, index) => {
                    return (
                      <>
                        <TableRow
                          className={`${auditLog.isDetailsVisible ? '' : 'tr-td-border-transparent'}`}
                          hover
                          tabIndex={-1}
                          key={'pc-' + index}
                        >
                          <TableCell data-cell={headCells[0].label}>
                            {getReadableDate(auditLog.event_timestamp)}
                          </TableCell>

                          <TableCell data-cell={headCells[1].label}>
                            {auditLog.event_category}
                          </TableCell>

                          <TableCell data-cell={headCells[2].label}>
                            {auditLog.event_type}
                          </TableCell>
                          <TableCell data-cell={headCells[3].label}>
                            {auditLog.event_action}
                          </TableCell>

                          <TableCell data-cell={headCells[4].label}>
                            {auditLog.actor}
                          </TableCell>

                          <TableCell
                            data-cell={headCells[5].label}
                            style={{ color: 'var(--clr-success)' }}
                          >
                            <span
                              className={`text-capitalize ${
                                auditLog.event_status?.toLowerCase() ===
                                'success'
                                  ? 'available'
                                  : auditLog.event_status?.toLowerCase() ===
                                      'failure'
                                    ? 'pending'
                                    : 'disable'
                              }`}
                            >
                              {auditLog.event_status}
                            </span>
                          </TableCell>
                          <TableCell
                            data-cell="Details"
                            onClick={() => {
                              toggleIsDetailsVisible(auditLog.id);
                            }}
                          >
                            {auditLog.isDetailsVisible ? (
                              <i className="bi bi-caret-up-fill" />
                            ) : (
                              <i className="bi bi-caret-down-fill" />
                            )}
                          </TableCell>
                        </TableRow>

                        {auditLog.isDetailsVisible && (
                          <TableRow>
                            <TableCell
                              className="tr-td-special-row"
                              colSpan={7}
                            >
                              <Collapse
                                in={auditLog.isDetailsVisible}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Paper className="tr-special-row pt-2">
                                  <TableCell data-cell="Actor Type">
                                    <span className="me-2 d-none d-md-inline fw-bold">
                                      Actor Type:
                                    </span>
                                    {auditLog.actor_type}
                                  </TableCell>
                                  <TableCell data-cell="Actor Id">
                                    <span className="me-2 d-none d-md-inline fw-bold">
                                      Actor Id:
                                    </span>
                                    {auditLog.actor_id}
                                  </TableCell>
                                  <TableCell data-cell="Client IP">
                                    <span className="me-2 d-none d-md-inline fw-bold">
                                      Client IP:
                                    </span>
                                    {auditLog.client_ip_address}
                                  </TableCell>
                                  <TableCell data-cell="Client Agent">
                                    {auditLog?.client_user_agent ? (
                                      <span>
                                        {auditLog.client_user_agent.length >
                                        30 ? (
                                          <>
                                            <span className="me-2 d-none d-md-inline fw-bold">
                                              Client Agent:
                                            </span>
                                            {auditLog.client_user_agent.slice(
                                              0,
                                              30
                                            )}
                                            <span
                                              className="view-more"
                                              onClick={() =>
                                                openViewMore({
                                                  title: 'Client Agent',
                                                  body: auditLog.client_user_agent,
                                                })
                                              }
                                            >
                                              ...View More
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <span className="me-2 d-none d-md-inline fw-bold">
                                              Client Agent:
                                            </span>
                                            {auditLog.client_user_agent}
                                          </>
                                        )}
                                      </span>
                                    ) : (
                                      <>
                                        <span className="me-2 d-none d-md-inline fw-bold">
                                          Client Agent:
                                        </span>
                                        <span> {'--'}</span>
                                      </>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    data-cell="Request"
                                    onClick={() =>
                                      responseRequestModalHandler(
                                        true,
                                        'Request',
                                        auditLog?.request
                                      )
                                    }
                                  >
                                    <span className="id">Request</span>
                                  </TableCell>
                                  <TableCell
                                    data-cell="Response"
                                    onClick={() =>
                                      responseRequestModalHandler(
                                        true,
                                        'Response',
                                        auditLog?.response
                                      )
                                    }
                                  >
                                    <span className="id">Response</span>
                                  </TableCell>
                                </Paper>
                                <Paper className="tr-special-row pb-2">
                                  <TableCell data-cell="Description">
                                    {auditLog?.message ? (
                                      <span>
                                        {auditLog.message.length > 50 ? (
                                          <>
                                            <span className="me-2 d-none d-md-inline fw-bold">
                                              Description:
                                            </span>
                                            {auditLog.message.slice(0, 50)}
                                            <span
                                              className="view-more"
                                              onClick={() =>
                                                openViewMore({
                                                  title: 'Description',
                                                  body: auditLog.message,
                                                })
                                              }
                                            >
                                              ...View More
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <span className="me-2 d-none d-md-inline fw-bold">
                                              Description:
                                            </span>
                                            {auditLog.message}
                                          </>
                                        )}
                                      </span>
                                    ) : (
                                      <>
                                        <span className="me-2 d-none d-md-inline fw-bold">
                                          Description:
                                        </span>
                                        <span> {'--'}</span>
                                      </>
                                    )}
                                  </TableCell>
                                </Paper>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    );
                  }
                )}
              </TableBody>
            )}
          </Table>

          {isAPILoading ? (
            <SkeletonLoader />
          ) : auditLogs?.length > 0 ? (
            <TablePagination
              className="table-pagination"
              rowsPerPageOptions={[10, 20, 30, 50, 100]}
              component="div"
              count={auditLogsTotalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showLastButton={true}
              showFirstButton={true}
            />
          ) : !isAPILoading && auditLogs?.length === 0 ? (
            <p className="text_color text-center no-data-msg mt-2">
              No Data Found
            </p>
          ) : null}
          <></>
        </div>
      </CommonCard>

      {isModalVisible && (
        <AuditLogsModal
          show={isModalVisible}
          modalTitle={responseRequestModalType}
          handleClose={() => responseRequestModalHandler(false)}
          dataObj={responseRequestModalData}
        />
      )}

      {viewMore && (
        <ViewMoreV2
          show={viewMore}
          handleClose={closedViewMore}
          title={viewMoreData?.title}
          body={viewMoreData?.body}
        />
      )}
    </Layout>
  );
}
